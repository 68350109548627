/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-10-10 16:38:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-16 19:46:13
 * @FilePath: /duomengde_pc/src/utils/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import qs from "qs";
import { getLocalStorageStr } from "./common";
import { apiUrl, apiUrlSub, uscUrl, apiUrlPay, shoppUrl } from "./config";
import store from "@/store/index";
import { platform } from "./config";
import { ElMessage } from "element-plus";
//import { useRouter, useRoute } from 'vue-router';
import router from "../router";
const instance1 = axios.create({
    baseURL: apiUrl,
    timeout: 50000
});
const respCode = (resq, response) => {
    /**
     * 后台code字段声明
     * SYSTEM_SUCCESS( errCode: 0,errlnfo: "success")
    SYSTEM_ERROR( errCode: -999errlnfo: "system Error"),SYSTEM_EXCEPTION(errCode:-998，errlnfo: "system Exceptions")
    ILLEGALITY_ERROR( errCode: 400,errlnfo:"请求非法")，
    BIZ_ERROR( errCode: 500,errlnfo:"业务异常")
    UNAUTHORIZED( errCode:10000，errlnfo:"未授权")
    INVALID_CLIENT( errCode: 10001,errlnfo:"用户认证失败")
    ILLEGAL_TOKEN( errCode: 10002,errlnfo:"非法的授权信息")
    FORBIDDEN( errCode:10003errlnfo:"授权过期，请重新登录")
     */
    if (
        response.data.code === 10000 ||
        response.data.code === 10001 ||
        response.data.code === 10002 ||
        response.data.code === 10003
    ) {
        if (resq.url != "api/shopp/checkToken") {
            ElMessage.warning(response.data.msg);
            setTimeout(() => {
                router.push({ path: "/login", params: {} });
                store.commit("clearAllData");
            }, 2000);
        }
    }
};
//const router = useRouter()
export const get = async (url, data = {}) => {
    let instance = axios.create({
        baseURL: apiUrl,
        timeout: 50000
    });
    url.slice(0, 3) == "api" && url.slice(4, 9) == "shopp"
        ? (instance = axios.create({ baseURL: shoppUrl, timeout: 50000 }))
        : url.slice(0, 3) == "api" && url.slice(4, 10) == "system"
        ? (instance = axios.create({ baseURL: uscUrl, timeout: 50000 }))
        : url.slice(0, 3) == "api" && url.slice(4, 7) == "pay"
        ? (instance = axios.create({ baseURL: apiUrlPay, timeout: 50000 }))
        : url.slice(0, 3) == "api"
        ? (instance = axios.create({ baseURL: apiUrlSub, timeout: 50000 }))
        : (instance = axios.create({ baseURL: apiUrl, timeout: 50000 }));
    let res = await initRequestHeader("get", url, data);
    data = res.data;
    return new Promise((resolve, reject) => {
        instance.get(res.url, { params: data, headers: res.headers }).then(
            (response) => {
                resolve(response.data);
                respCode(res, response);
            },
            (err) => {
                reject(err);
            }
        );
    });
};

export const post = async (url, data = {}, type = "urlencoded") => {
    let instance = axios.create({
        baseURL: apiUrl,
        timeout: 50000
    });
    url.slice(0, 3) == "api" && url.slice(4, 9) == "shopp"
        ? (instance = axios.create({ baseURL: shoppUrl, timeout: 50000 }))
        : url.slice(0, 3) == "api" && url.slice(4, 10) == "system"
        ? (instance = axios.create({ baseURL: uscUrl, timeout: 50000 }))
        : url.slice(0, 3) == "api" && url.slice(4, 7) == "pay"
        ? (instance = axios.create({ baseURL: apiUrlPay, timeout: 50000 }))
        : url.slice(0, 3) == "api"
        ? (instance = axios.create({ baseURL: apiUrlSub, timeout: 50000 }))
        : (instance = axios.create({ baseURL: apiUrl, timeout: 50000 }));
    let res = await initRequestHeader("post", url, data, type);
    return new Promise((resolve, reject) => {
        instance
            .post(res.url, res.data, {
                headers: res.headers
            })
            .then(
                (response) => {
                    resolve(response.data);
                    respCode(res, response);
                },
                (err) => {
                    reject(err);
                }
            );
    });
};

//instance1.interceptors.response.use(
//    function (response) {
//        // 对响应数据做点什么
//        return response;
//    },
//    function (error) {
//        // 如果token失效，则重定向到首页
//        console.log(8888888888888888)
//        const history = useHistory();
//        if (error.response && error.response.status === 500) {
//            history.push('/login');
//        }
//        // 可以选择向用户显示错误信息
//        return Promise.reject(error);
//    }
//);

/**
 * 初始化请求头和数据
 * @zjf-2020-12-25
 */
const initRequestHeader = async (method, url, data = {}, type = "urlencoded") => {
    let result = {};
    // 集采： 558589960127521000 旧 558589960127529344
    // 工采： 558589960127522000
    let tenantId = platform == 1 ? "TENANT_SHOPP_GC" : "TENANT_SHOPP_JC"; //1-大石工采，2-大石集采
    let headers = {
        "TENANT-ID": tenantId //1-大石工采，2-大石集采
    };

    //用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
    if (getLocalStorageStr("access_token")) {
        let cur_time = new Date().getTime();
        if (cur_time - getLocalStorageStr("time") * 1 > 60 * 60 * 1000 * 15 * 24) {
            let param = new FormData();
            param.append("grant_type", "refresh_token");
            param.append("refresh_token", getLocalStorageStr("refresh_token"));
            await instance1
                .post("v3/frontLogin/oauth/token", param, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization:
                            "Basic VVcxS2FsSnVTblppYmxFOTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09",
                        "TENANT-ID": tenantId
                    }
                })
                .then(
                    (response) => {
                        if (response.data.state == 200) {
                            localStorage.setItem("access_token", response.data.data.access_token);
                            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
                        }
                    },
                    (err) => {
                        console.log("更新access_token出错：", err);
                    }
                );
        }
    }

    if (method == "post") {
        if (type == "urlencoded") {
            headers = { "Content-Type": "application/x-www-form-urlencoded", "TENANT-ID": tenantId };
            data = qs.stringify(data);
        } else if (type == "json") {
            headers = { "Content-Type": "application/json", "TENANT-ID": tenantId };
            data = JSON.parse(JSON.stringify(data));
        } else if (type == "form") {
            //form-data
            headers = { "Content-Type": "multipart/form-data", "TENANT-ID": tenantId };
            let tmp_data = new FormData();
            console.log(data);
            Object.keys(data).forEach((item) => {
                tmp_data.append(item, data[item]);
            });
            data = tmp_data;
        }
    }

    if (url.indexOf("frontLogin/oauth/token") > -1) {
        headers.Authorization = "Basic VVcxS2FsSnVTblppYmxFOTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09";
    } else {
        let token = getLocalStorageStr("access_token");
        headers.Authorization = "Bearer " + token;
        headers = { "X-Access-Token": token, ...headers };
    }
    headers.sellTerminal = platform; //**销售终端：1-大石工采，2-大石集采 */
    result = { url, data, headers };
    return result;
};
