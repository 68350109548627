/*
 * @Author: your name
 * @Date: 2020-12-25 19:22:45
 * @LastEditTime: 2023-01-04 13:46:37
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import { title } from "../utils/config";
import { sldStatEvent, updateStatCommonProperty } from "../utils/stat.js";
import { apiUrl } from '../utils/config'
import { wetChatLogin } from '../utils/common.js'
function checkLogin(next, to) {
  const { loginFlag } = localStorage;
  loginFlag ? next() : next({ name: 'Login', query: { redirectUrl: encodeURIComponent(to.fullPath) } });
}

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import(/* webpackChunkName: "home" */ '../views/home/Home'),
  redirect: '/index',
  children: [
    {
      path: '/index',
      name: 'HomeIndex',    //首页
      component: () => import(/* webpackChunkName: "home" */ '../views/home/Index'),
    },
    {
      path: '/topic',
      name: 'Topic',   //专题页
      component: () => import(/* webpackChunkName: "home" */ '../views/home/Topic'),
    }
    ,
    {
      path: '/coupon',
      name: 'CouponCenter',   //领劵中心
      component: () => import(/* webpackChunkName: "home" */ '../views/home/couponCenter'),
    }
  ],
}, {
  path: '/goods',
  name: 'Goods', //商品模块
  component: () => import(/* webpackChunkName: "home" */ '../views/goods/Header'),
  children: [
    {
      path: 'category',
      name: 'GoodsCategory',//商品分类页面
      component: () => import(/* webpackChunkName: "home" */ '../views/goods/Category'),
    },
    {
      path: 'list',
      name: 'GoodsList',
      component: () => import(/* webpackChunkName: "home" */ '../views/goods/GoodsList'),
    },
    {
      path: '/brand',
      name: 'Brand', //品牌中心
      component: () => import(/* webpackChunkName: "home" */ '../views/goods/Brand')
    },
  ]
},
{
  path: '/goods',
  name: 'GoodsDetail',//商品详情页头部
  component: () => import(/* webpackChunkName: "home" */ '../views/goods/GoodsDetailHeader'),
  redirect: '/goods/detail',
  children: [
    {
      path: 'detail',
      name: 'GoodsDetail',//商品详情页
      component: () => import(/* webpackChunkName: "home" */ '../views/goods/GoodsDetail'),
    }
  ]
},
{
  path: '/member',
  name: 'Member',
  component: () => import(/* webpackChunkName: "home" */ '../views/member/Common'),
  redirect: '/member/index',
  children: [
    {
      path: '/member/index',
      name: 'MemberIndex',    //个人中心
      component: () => import(/* webpackChunkName: "home" */ '../views/member/Index'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/collect',
      name: 'MemberCollect',    //我的收藏
      component: () => import(/* webpackChunkName: "home" */ '../views/member/Collect'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/footprint',
      name: 'MemberFootprint',    //我的足迹
      component: () => import(/* webpackChunkName: "home" */ '../views/member/Footprint'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/address/list',
      name: 'memberAddressList',    //收货地址列表
      component: () => import(/* webpackChunkName: "home" */ '../views/member/AddressList'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/list',
      name: 'memberOrderList',    //我的订单
      component: () => import('../views/member/order/List'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/approvalList',
      name: 'memberApprovalList',    //我的审批
      component: () => import('../views/member/order/approvalList'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/supplierOrders',
      name: 'memberSupplierOrders',    //我的供应商订单
      component: () => import('../views/member/order/supplierOrders'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/refund/apply', //申请退款
      name: 'ApplyRefund',
      component: () => import('../views/member/order/ApplyRefund'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/refund/barter', //申请换货
      name: 'Applybarter',
      component: () => import('../views/member/order/barter'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/refund/detail', //售后详情
      name: 'RefundDetail',
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/RefundDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/exchange/detail', //售后换货详情
      name: 'ExchangeDetail',
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/exchangeDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/evaluation',
      name: 'MemberOrderEvaluation',    //评价订单列表
      component: () => import('../views/member/order/Evaluation'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/aftersales', //售后列表
      name: 'AfterSalesList',
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/AfterSalesList'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/logistics', //填写物流信息
      name: 'Logistics',
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/Logistics'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    }, {
      path: '/member/coupon',
      name: 'MemberCoupon',    //我的银行卡
      component: () => import('../views/member/money/Coupon'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/bankCard',
      name: 'MemberbankCard',    //我的优惠卷
      component: () => import('../views/member/money/bankCard'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/AccountNow',
      name: 'MemberAccountNow',    //我的优惠卷
      component: () => import('../views/member/money/AccountNow'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/AccountNow1',
      name: 'MemberAccountNow1',    //商户服务协议
      component: () => import('../views/member/money/AccountNow1'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/AccountNow2',
      name: 'MemberAccountNow2',    //个人绑卡
      component: () => import('../views/member/money/AccountNow2'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/cardBind',
      name: 'cardBind',    //协议绑卡
      component: () => import('../views/member/money/cardBind'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/AddBankCard',
      name: 'MemberAddBankCard',    //绑定银行卡
      component: () => import('../views/member/money/AddBankCard'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/Payment',
      name: 'MemberPayment',    //打款验证
      component: () => import('../views/member/money/Payment'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/balance',
      name: 'MemberBalance',    //我的余额
      component: () => import('../views/member/money/Balance'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/openAccount',
      name: 'MemberOpenAccount',    //我的余额(未开户)
      component: () => import('../views/member/money/OpenAccount'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/balance/info',
      name: 'MemberBalanceInfo',    //提现详情
      component: () => import('../views/member/money/OutputInfo'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/balance/operate',
      name: 'MemberOutput',    //申请提现
      component: () => import('../views/member/money/OutputOperate'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/account',
      name: 'MemberAccount',    //账号安全
      component: () => import('../views/member/center/Account'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/phone',
      name: 'MemberPhoneMange',    //手机号管理
      component: () => import('../views/member/center/PhoneMange'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/email',
      name: 'memberEmailMange',    //邮箱管理
      component: () => import('../views/member/center/EmailMange'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/pwd/login',
      name: 'MemberLoginPassword',    //登陆密码
      component: () => import('../views/member/center/LoginPassword'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/pwd/pay',
      name: 'MemberPayPassword',    //支付密码
      component: () => import('../views/member/center/PayPassword'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/pwd/reset',
      name: 'MemberResetPassword',    //重置支付密码
      component: () => import('../views/member/center/ResetPassword'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/info',
      name: 'MemberInfo',    //会员信息
      component: () => import('../views/member/Info'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/recharge',
      name: 'MemberRecharge',    //充值
      component: () => import('../views/member/money/Recharge'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/myPoint',
      name: 'MyPoint',
      component: () => import('../views/member/myPoint'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/pointOrder/list',
      name: 'pointOrderList',
      component: () => import('../views/member/pointOrder/list'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/account/list',
      name: 'MemberCollectList', //子账号管理
      component: () => import('../views/member/account/list'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path:'/member/account/Sub-sector',
      name:'SubSectorList',//部门管理
      component:()=>import('../views/member/account/Sub-sector'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    // {
    //   path: '/member/account/category',
    //   name: 'accountCategory',
    //   component: () => import('../views/member/account/category'),
    //   beforeEnter(to, from, next) { checkLogin(next, to); }
    // },
    {
      path: '/member/account/manage',
      name: 'memberAccountManage',//账号组
      component: () => import('../views/member/account/manage'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/settle/agreement',
      name: 'memberAgreement',//入驻供应商
      component: () => import('../views/member/settle/agreement'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/settle/enterprise',
      name: 'memberEnterprise',//入驻供应商2
      component: () => import('../views/member/settle/enterprise'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/settle/storeInformation',
      name: 'storeInformation',//入驻供应商3
      component: () => import('../views/member/settle/storeInformation'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/settle/mailbox',
      name: 'mailbox',//入驻供应商4
      component: () => import('../views/member/settle/mailbox'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/procurementPlanList',
      name: 'procurementPlanList',//采购计划
      component: () => import('../views/member/procurementPlan/procurementPlanList.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/monthlylist',
      name: 'monthlylist',//月度采购计划
      component: () => import('../views/member/procurementPlan/monthlylist.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/procurementPlanApprovalList',
      name: 'procurementPlanApprovalList',//采购计划审批
      component: () => import('../views/member/procurementPlan/procurementPlanApprovalList.vue'),
    },
    {
      path: '/member/procurement/monthlylist',
      name: 'monthlylist',//月度采购计划
      component: () => import('../views/member/procurementPlan/monthlylist.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/mySupplier/mySupplierList',
      name: 'mySupplierList',//我的供应商
      component: () => import('../views/member/mySupplier/List.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/mySupplier/addSupplier',
      name: 'addSupplier',//供应商添加
      component: () => import('../views/member/mySupplier/addSupplier.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/payment/MonthlyStatement',
      name: 'MonthlyStatement',//当月账单
      component: () => import('../views/member/payment/MonthlyStatement.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/payment/Settlement',
      name: 'Settlement',//账期结算
      component: () => import('../views/member/payment/Settlement.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/payment/HistoricalStatement',
      name: 'HistoricalStatement',//历史账单
      component: () => import('../views/member/payment/HistoricalStatement.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/payment/HistoricalStatementDetail',
      name: 'HistoricalStatementDetail',//历史账单
      component: () => import('../views/member/payment/HistoricalStatementDetail.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/msg',
  name: 'Msg',
  component: () => import(/* webpackChunkName: "home" */ '../views/msg/Header'),//消息中心公共部分
  redirect: '/msg/list',
  children: [
    {
      path: 'list',
      name: 'MsgList',    //消息列表
      component: () => import(/* webpackChunkName: "home" */ '../views/msg/List'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: 'setting',
      name: 'MsgSetting',    //消息接收设置
      component: () => import(/* webpackChunkName: "home" */ '../views/msg/Setting'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/member/order/detail',
  name: 'OrderDetail',//订单详情
  component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
  redirect: '/member/order/detail',
  children: [
    {
      path: '/member/order/detail',
      name: 'memberOrderDetail',    //订单详情
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/detail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/orderReview',
      name: 'memberOrderReview',    //订单审核
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/orderReview'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/order/orderReviews',
      name: 'memberOrderReviews',    //订单审核
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/orderReviews'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/member/procurement/procuremmentPlanDetail',
  name: 'procuremmentPlanDetail',//采购计划详情
  component: () => import('../views/member/Header'),
  //beforeEnter(to, from, next) { checkLogin(next, to); }
  redirect: '/member/procurement/procuremmentPlanDetail',
  children: [
    {
      path: '/member/procurement/procuremmentPlanDetail',
      name: 'procuremmentPlanDetail',//采购计划报价中详情
      component: () => import('../views/member/procurementPlan/procuremmentPlanDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/waitQuotationDetail',
      name: 'waitQuotationDetail',//待报价详情
      component: () => import('../views/member/procurementPlan/waitQuotationDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    
    {
      path: '/member/procurement/editQuotationDetail',
      name: 'editQuotationDetail',//编辑报价详情
      component: () => import('../views/member/procurementPlan/editQuotationDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/OtherRecommendations',
      name: 'OtherRecommendations',
      component: () => import('../views/member/procurementPlan/OtherRecommendations.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/quotedPriceDetail',
      name: 'quotedPriceDetail',//报价详情
      component: () => import('../views/member/procurementPlan/quotedPriceDetail.vue'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/procurementPlanApprovalDetail',
      name: 'procurementPlanApprovalDetail',//采购计划审批详情
      component: () => import('../views/member/procurementPlan/procurementPlanApprovalDetail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/member/procurement/addPlan',
  name: 'addPlan1',
  component: () => import('../views/member/Header'),
  beforeEnter(to, from, next) { checkLogin(next, to); },
  redirect: '/member/procurement/addPlan',
  children: [
    {
      path: '/member/procurement/addPlan',
      name: 'addPlan',
      component: () => import('../views/member/procurementPlan/addPlan'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: '/member/procurement/addedMonthly',
      name: 'addedMonthly',
      component: () => import('../views/member/procurementPlan/addedMonthly'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    }
  ]
},

{
  path: '/member/order/evaluate',
  name: 'OrderEvaluate',//订单评价
  component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
  redirect: '/member/order/evaluate',
  children: [
    {
      path: '/member/order/evaluate',
      name: 'memberOrderEvaluate',    //订单评价
      component: () => import(/* webpackChunkName: "home" */ '../views/member/order/Evaluate'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/settleHeader',
  name: 'settleHeader',
  component: () => import(/* webpackChunkName: "home" */ '../views/member/settle/Header'),//消息中心公共部分
  redirect: '/member/settle/results',
  children: [
    {
      path: '/member/settle/results',
      name: 'memberOrderResults',    //
      component: () => import(/* webpackChunkName: "home" */ '../views/member/settle/results'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/login',
  name: 'Login',//用户登录页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Index'),
},
{
  path: '/pwd/forget',
  name: 'ForgetPassword',//用户找回密码页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/ForgetPassword'),
},
{
  path: '/register',
  name: 'register',//用户注册页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Register'),
},
{
  path: '/priseRegister',
  name: 'priseRegister',//企业用户注册页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/priseRegister'),
},
{
  path: '/registrationReview',
  name: 'registrationReview',//企业用户注册页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/registrationReview'),
},
{
  path: '/agreement',
  name: 'agreement',//用户注册协议页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/Agreement'),
},
{
  path: '/bindMobile',
  name: 'bindMobile',//用户注册协议页面
  component: () => import(/* webpackChunkName: "home" */ '../views/member/login/bindMobile'),
},

{
  path: '/buy',
  name: 'Buy',//下单页入口
  component: () => import(/* webpackChunkName: "home" */ '../views/buy/Header'),
  children: [
    {
      path: 'confirm',
      name: 'BuyConfirmOrder',//下单页面
      component: () => import('../views/buy/ConfirmOrder'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: 'pay',
      name: 'Pay',//支付页面
      component: () => import('../views/buy/pay'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: 'reconciliationPay',
      name: 'reconciliationPay',//支付页面
      component: () => import('../views/buy/reconciliationPay'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
    {
      path: 'toBind',
      name: 'toBind',//支付页面
      component: () => import('../views/buy/toBind'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/article',
  name: 'article',//文章页面
  component: () => import(/* webpackChunkName: "home" */ '../views/article/article'),
},
{
  path: '/store',
  name: 'store',
  component: () => import(/* webpackChunkName: "home" */ '../views/store/Header'),
  children: [
    {
      path: 'list',
      name: 'StoreList',//店铺列表模块
      component: () => import(/* webpackChunkName: "home" */ '../views/store/storeList'),
    }
  ]
}, {
  path: '/store/detail',
  name: 'StoreDetail',//店铺模块
  component: () => import(/* webpackChunkName: "home" */ '../views/store/StoreHeader'),
  redirect: '/store/index',
  children: [
    {
      path: '/store/index',
      name: 'storeIndex',//店铺首页
      component: () => import(/* webpackChunkName: "home" */ '../views/store/Index'),
    },
    {
      path: '/store/goods',
      name: 'storeGoods',//店铺商品列表
      component: () => import(/* webpackChunkName: "home" */ '../views/store/GoodsList'),
    }
  ]
}, {
  path: '/cart',
  name: 'Cart', //商品模块
  component: () => import(/* webpackChunkName: "home" */ '../views/cart/Header'),
  children: [
    {
      path: 'index',
      name: 'CartIndex',    //购物车页面
      component: () => import(/* webpackChunkName: "home" */ '../views/cart/CartIndex'),
    },
  ]
},
{
  path: '/point',
  name: 'Point',//积分商城
  component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/Header'),
  redirect: '/point/index',
  children: [
    {
      path: 'index',
      name: 'PointIndex',//积分首页
      component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/Index'),
    },
    {
      path: 'list',
      name: 'PointList',//积分商品列表
      component: () => import(/* webpackChunkName: "home" */ '../views/point/pointIndex/List'),
    }
  ]
},
{
  path: '/point/goods',
  name: 'PointDetail',//积分商品详情
  component: () => import(/* webpackChunkName: "home" */ '../views/point/goods/Header'),
  redirect: '/point/detail',
  children: [
    {
      path: '/point/detail',
      name: 'detail',//积分商品详情
      component: () => import(/* webpackChunkName: "home" */ '../views/point/goods/Detail'),
    }
  ]
},
{
  path: '/point/order/confirm',
  name: 'PointOrder',//积分确认订单
  component: () => import(/* webpackChunkName: "home" */ '../views/point/exchange/ConfirmOrder'),
  beforeEnter(to, from, next) { checkLogin(next, to); }
},
{
  path: '/point/pay',
  name: 'PointPay',//积分支付页面
  component: () => import('../views/point/exchange/pay'),
  beforeEnter(to, from, next) { checkLogin(next, to); }
},
  {
    path: '/member/order/detailspage',
    name: 'Detailspage',//供应商订单详情
    component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
    redirect: '/member/order/detailspage',
    children: [
      {
        path: '/member/order/detailspage',
        name: 'memberDetailspage',    //供应商订单详情
        component: () => import(/* webpackChunkName: "home" */ '../views/member/order/detailspage'),
        beforeEnter(to, from, next) { checkLogin(next, to); }
      },
      {
        path: '/member/order/orderReview',
        name: 'memberOrderReview',    //订单审核
        component: () => import(/* webpackChunkName: "home" */ '../views/member/order/orderReview'),
        beforeEnter(to, from, next) { checkLogin(next, to); }
      },
      {
        path: '/member/order/orderReviews',
        name: 'memberOrderReviews',    //订单审核
        component: () => import(/* webpackChunkName: "home" */ '../views/member/order/orderReviews'),
        beforeEnter(to, from, next) { checkLogin(next, to); }
      },
    ]
  },
{
  path: '/member/pointOrder/detail',
  name: 'PointOrderPay',//积分订单详情
  component: () => import(/* webpackChunkName: "home" */ '../views/member/Header'),
  redirect: '/member/pointOrder/detail',
  children: [
    {
      path: '/member/pointOrder/detail',
      name: 'memberPointOrderPay', //积分订单详情
      component: () => import(/* webpackChunkName: "home" */ '../views/member/pointOrder/detail'),
      beforeEnter(to, from, next) { checkLogin(next, to); }
    },
  ]
},
{
  path: '/service',
  name: 'serviceChat',
  component: () => import('../views/service/chatPage'),
  beforeEnter: (to, from, next) => { checkLogin(next, to); }
},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let code = to.query.code
  let state = to.query.state
  if (code && state) {
    wetChatLogin(to, next)
  } else {
    next()
  }

})

router.afterEach((to, from) => {
  window.document.title = title;
  let url = apiUrl.substring(0, apiUrl.length - 1);
  //商品详情页、店铺的页面需要单独统计，但是需要把pageUrl和referrerPageUrl先存进去
  let specialPages = [
    '/goods/detail',//商品详情页
    '/store/goods',//店铺首页
  ];
  let statPvFlag = true;
  for (let i in specialPages) {
    if (specialPages[i].indexOf(to.path) > -1) {
      statPvFlag = false;
      break;
    }
  }

  if (!statPvFlag) {
    //不需要pv类型的统计
    updateStatCommonProperty({ pageUrl: url + to.path, referrerPageUrl: url + from.path });
  } else {
    setTimeout(() => {
      sldStatEvent({ behaviorType: 'pv', pageUrl: url + to.path, referrerPageUrl: url + from.path });
    }, 3000)
  }
})

export default router
